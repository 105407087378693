import AppAdapter from "../../utils/adapter";
import PollService from "./pollService";
import { FieldPropsSource, ResponsePropsSource } from "./types";

export const fetchResponse = async (
  fieldId,
  userId,
  setUserResponse: (any) => void
) => {
  const payload = await PollService.getResponse(fieldId, userId);

  if (payload.ok) {
    if (payload.result.data) {
      const response: ResponsePropsSource = AppAdapter.camelCaseKeys(
        payload.result.data
      );
      setUserResponse(response);
    }
  }
};

export const createResponse = async (
  fieldId,
  userId,
  options,
  setUserResponse: (any) => void
) => {
  const payload = await PollService.createResponse({
    userPolling: {
      additionalFieldId: Number(fieldId),
      userId: userId,
      options: options,
    },
  });

  if (payload.ok) {
    const response = AppAdapter.camelCaseKeys(payload.result.data);

    setUserResponse(response);
  }
};

export const updateResponse = async (
  fieldId,
  userId,
  responseId,
  options,
  setUserResponse: (any) => void
) => {
  const payload = await PollService.updateResponse(
    {
      userPolling: {
        additionalFieldId: Number(fieldId),
        userId: userId,
        options: options,
      },
    },
    responseId
  );

  if (payload.ok) {
    const response = AppAdapter.camelCaseKeys(payload.result.data);

    setUserResponse(response);
  }
};

export const setAdditionField = async (pollId, setField: (any) => void) => {
  const response = await PollService.getAdditionalFields("Polling", pollId);

  if (response.ok) {
    const fields: Array<FieldPropsSource> = AppAdapter.camelCaseKeys(
      response.result.data
    );
    setField(fields[0]);
  }
};

export const setResults = async (fieldId, setResults: (any) => void) => {
  const payload = await PollService.getPollResults(fieldId);

  if (payload.ok) {
    const response = payload.result;
    setResults(response);
  }
};
