window.countdownTimers = window.countdownTimers || []
import moment from 'moment'

class CountdownTimer {
  constructor(duration, startedAt, stoppedAt, autoStart, callback) {
    this.duration = parseInt(duration)
    this._startedAt = startedAt
    this._stoppedAt = stoppedAt
    this._autoStart = autoStart
    this._isStarted = false
    this._timer = duration * 60 - 1
    this._minutes = 0
    this._seconds = 0
    this._countdownID
    this._callback = callback
    this.calculateTime()
    this.autoStartCountdown()
  }

  isStarted() {
    return this._isStarted
  }

  setIsStarted(val) {
    this._isStarted = val
  }

  getTimer() {
    return this._timer
  }

  autoStartCountdown() {
    if (!this._startedAt) {
      return
    }

    let startedAt = moment(this._startedAt)
    let stoppedAt = this._stoppedAt ? moment(this._stoppedAt) : moment()
    let timePassed = (stoppedAt - startedAt) / 1000

    if (timePassed >= this.duration * 60) {
      return
    }

    this._timer = this._timer - Math.round(timePassed)
    this.calculateTime()
    this._callback(this)

    if (this._autoStart) {
      this.start()
    }
  }

  start() {
    if (this.isStarted()) {
      return
    }

    this.setIsStarted(true)
    this._countdownID = setInterval(() => {
      this.calculateTime()
      this._callback(this)

      if (--this._timer < 0) {
        this._timer = 0
        this.stop()
      }
    }, 1000)
    window.countdownTimers.push(this._countdownID)
  }

  stop() {
    this.setIsStarted(false)
    let countdownIndex = window.countdownTimers.indexOf(this._countdownID)

    clearInterval(this._countdownID)
    window.countdownTimers.splice(countdownIndex, 1)
  }

  reset() {
    this.stop()
    this._timer = this.duration * 60
    this.calculateTime()
    this._callback(this)
    this._timer = this._timer - 1
  }

  calculateTime() {
    let minutes = parseInt(this._timer / 60, 10),
      seconds = parseInt(this._timer % 60, 10)

    this._minutes = minutes < 10 ? '0' + minutes : minutes
    this._seconds = seconds < 10 ? '0' + seconds : seconds
  }

  toString() {
    return `${this._minutes}:${this._seconds}`
  }
}

export default CountdownTimer
