import moment from 'moment'

const MessageItem = (message, currentUserId) => {
  const createdAt = moment(message.created_at)
  const owner = message.owner
  const isOwner = currentUserId === owner.id

  return `
    <div
      class="component-chat-item ${isOwner ? 'reverse' : ''}"
      data-action="mouseleave->chat#hideDropdown"
      data-message-hexdigest="${message.hexdigest_id}"
      data-message-id="${message.id}"
    >
      <div class="component-chat-item-avatar">
        <img src="${owner.avatar.card.url}" crossorigin="anonymous" class="rounded-circle">
      </div>
      <div class="component-chat-item-body">
        <div class="component-chat-item-message">
          ${renderAttachments(message.attachments)}
          ${message.text ? `<div class="component-chat-item-text">${message.text}</div>` : ''}
          <div class="component-chat-item-actions">
            ${
              isOwner
                ? `<div class="dropdown">
                    <span class="dc-icons ph-gear-six btn btn-link p-0" data-toggle="dropdown" data-action="click->chat#showDropdown"></span>
                    <div class="dropdown-menu p-1">
                      <a class="dropdown-item" data-action="click->chat#removeMessage" href="#">${I18n.t('actions.delete')}</a>
                    </div>
                  </div>`
                : ''
            }
          </div>
        </div>
        <div class="component-chat-item-owner">${owner.full_name}</div>
        <div class="component-chat-item-time">
          <time
            datetime="${createdAt.format()}"
            data-local="time"
            data-format="%H:%M"
            title="${createdAt.format('LLL')}"
            data-localized=""
            aria-label="${createdAt.format('HH:mm')}"
          >
            ${createdAt.format('HH:mm')}
          </time>
        </div>
      </div>
    </div>
  `.trim()
}

const renderAttachments = (attachments) => {
  return `
    <div class="component-chat-item-attachments">
    ${
      attachments.map((attachment) => {
        return `
          <figure class="component-chat-item-image">
            <a data-fancybox="gallery" target="_blank" href="${attachment.url}">
              <img src="${attachment.thumbnail.url}" crossorigin="anonymous">
            </a>
          </figure>
        `
      })
    }
    </div>`.trim()
}

export default MessageItem
