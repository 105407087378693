import { UserResponseData } from "./types";
import AppAdapter from '../../utils/adapter';
import CONFIG from '../../utils/config';
import RestClient from '../../utils/restClient';

/**
 * service for polls
 */

class PollService {
  static async getPolls(mountableType: string, mountableId: number) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.POLLS}?mountable_type=${mountableType}&mountable_id=${mountableId}`);
      const { ok: responseOk } = response;
      if (!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: "error in fetching", result: null };
    }
  }

  static async getPollResults(additionalFieldId: number) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.POLL_RESULTS}/${additionalFieldId}`);
      const { ok: responseOk } = response;
      if (!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async getResponse(additionalFieldId: number, userId: number) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.POLL_RESPONSE}/${additionalFieldId}/${userId}`);
      const { ok: responseOk } = response;
      if (!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async createResponse(data: UserResponseData) {
    try {
      const response = await RestClient.post(CONFIG.URLS.USER_POLLS, AppAdapter.snakeCaseKeys(data));
      const { ok: responseOk } = response;
      if (!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async updateResponse(data: UserResponseData, id: number) {
    try {
      const response = await RestClient.patch(`${CONFIG.URLS.USER_POLLS}/${id}`, AppAdapter.snakeCaseKeys(data));
      const { ok: responseOk } = response;
      if (!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: 'error in fetching', result: null };
    }
  }

  static async getAdditionalFields(klassType: string, klassId: number) {
    try {
      const response = await RestClient.get(`${CONFIG.URLS.ADDITIONAL_FIELDS}?klass_type=${klassType}&klass_id=${klassId}`);
      const { ok: responseOk } = response;
      if (!responseOk) response.message = 'error in fetching';
      return response;
    } catch (e) {
      return { ok: false, message: "error in fetching", result: null };
    }
  }
}

export default PollService;
