// http://bootstrap-notify.remabledesigns.com/

import $ from 'jquery'
import 'bootstrap-notify'

const notify = (msg, options = {}) => {
  var defaultOption = Object.assign(
    {
      allow_dismiss: false,
      newest_on_top: true,
      delay: 3000,
      z_index: 1051,
      onShow: function () {
        this.css({ width: 'auto', 'min-width': '350px' })
      },
    },
    options
  )
  $.notify(msg, defaultOption)
}

export default notify
