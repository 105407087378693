const EVENT_TOOLS_NS = "/event-tools";
const USER_POLLS_NS = "/user_pollings";

const CONFIG = {
  URLS: {
    EVENTS: `${EVENT_TOOLS_NS}/events`,
    SESSIONS: `${EVENT_TOOLS_NS}/sessions`,
    TENANTS: `${EVENT_TOOLS_NS}/tenants`,
    TIMEZONES: `${EVENT_TOOLS_NS}/timezones`,
    POLLS: "/pollings",
    USER_POLLS: USER_POLLS_NS,
    POLL_RESULTS: `${USER_POLLS_NS}/poll_results`,
    POLL_RESPONSE: `${USER_POLLS_NS}/poll_response`,
    ADDITIONAL_FIELDS: "/additional_fields",
    FACILITATORS: `${EVENT_TOOLS_NS}/session_facilitators`,
    ZOOM_CONFIGURATIONS: `zoom_configuration`,
  },
};

export default CONFIG;
