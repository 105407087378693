
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: I18n.translations[I18n.locale]
      }
    },
    lng: I18n.locale,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      prefix: "%{",
      suffix: "}"
    },
    react: {
      useSuspense: false
    }
  });
