import { Deserializer, DeserializerOptions } from 'jsonapi-serializer';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

/**
 * utility for serialization and deserialization
 * @module class
 */
class AppAdapter {
  /**
   * method to deserialize the payload
   * @param {Object} dataSet
   * @param {Object} options
   * @return {Promise<*>}
   */
  static async deserialize(dataSet, options: DeserializerOptions = {}) {
    options.keyForAttribute = 'camelCase';
    const jsonDeserializer = new Deserializer(options);
    let deSerializedData;
    try {
      deSerializedData = await jsonDeserializer.deserialize(dataSet);
    } catch (e) {
      deSerializedData = null;
    }
    return deSerializedData;
  }

  static deserializeErrors(dataSet) {
    const errors = {};
    for(const name in dataSet?.errors) {
      const errorMessage = dataSet.errors[name].join(", ");
      errors[camelCase(name)] = {
        type: 'server',
        message: errorMessage,
        types: { 'server': errorMessage }
      };
    }
    return errors;
  }

  static snakeCaseKeys(obj) {
    if (!isObject(obj) || isEmpty(Object.keys(obj)) ) {
      return obj;
    } else if (isArray(obj)) {
      return obj.map((v) => this.snakeCaseKeys(v));
    }
    return reduce(obj, (r, v, k) => {
      return {
        ...r,
        [this.destroyKeyCheck(k)]: this.snakeCaseKeys(v),
      };
    }, {});
  }

  static camelCaseKeys(obj) {
    if (!isObject(obj) || isEmpty(Object.keys(obj)) ) {
      return obj;
    } else if (isArray(obj)) {
      return obj.map((v) => this.camelCaseKeys(v));
    }
    return reduce(obj, (r, v, k) => {
      return {
        ...r,
        [camelCase(k)]: this.camelCaseKeys(v),
      };
    }, {});
  }

  static destroyKeyCheck(key) {
    return key === '_destroy' ? key : snakeCase(key);
  }
}

export default AppAdapter;
