const extractCsrfToken = () => {
  return document.querySelector('meta[name=\'csrf-token\']')?.getAttribute('content');
};

export type JSONResponse = {
  ok: boolean;
  status: number;
  statusText: string;
  headers?: Headers;
  result?: unknown;
  message?: string;
};
/**
 * Service for making web api calls using es6 native `fetch`
 * @module class
 */
class RestClient {
  static defaultOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-csrf-token': extractCsrfToken(),
    },
    credentials: 'include', // include cookies with every request
  };

  // Inspired from http://api.jquery.com/jQuery.param/
  static encodeForUrl = (obj) => Object
    .keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');

  /**
   * Send HTTP requests and returns the formatted response
   * response object looks like this
   * {
   *    ok: true,
   *    status: 200,
   *    statusText: 'OK',
   *    headers: {},
   *    result: {}
   * }
   * @param {String} url API endpoint
   * @param {Object} options
   * @return {Promise} Returns the response object containing the status and data
   */
  static async request(url, options) {
    const allOptions = { ...this.defaultOptions, ...options };
    allOptions.headers = { ...this.defaultOptions.headers, ...options.headers };
    if (options.unsetContentType) {
      delete allOptions.headers['Content-Type'];
    }
    const response = await fetch(url, allOptions);
    const responseJson: JSONResponse = (
      ({
        ok, status, statusText, headers,
      }) => ({
        ok, status, statusText, headers,
      })
    )(response);
    try {
      responseJson.result = await response.json();
    } catch (ex) {
      responseJson.ok = false;
      responseJson.result = null;
    }
    return responseJson;
  }

  /**
   * Send HTTP GET request
   * @param {String} url  API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static get(url, payload = null) {
    const options = { method: 'GET' };
    let fullUrl = url;
    if (payload && Object.keys(payload).length !== 0) {
      fullUrl += `?${this.encodeForUrl(payload)}`;
    }
    return this.request(fullUrl, options);
  }

  /**
   * Send HTTP POST request
   * @param {String} url  API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static post(url, payload) {
    const options = { method: 'POST', body: JSON.stringify(payload) };
    return this.request(url, options);
  }

  /**
   * Send HTTP PATCH request
   * @param {String} url API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static patch(url, payload) {
    const options = { method: 'PATCH', body: JSON.stringify(payload) };
    return this.request(url, options);
  }

  /**
   * Send HTTP DELETE request
   * @param {String} url  API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
   static delete(url, payload = {}) {
    const options = { method: 'DELETE', body: JSON.stringify(payload) };
    return this.request(url, options);
  }

  static formDataRequest(requestMethod, url, payload, namespace = null) {
    const formData = RestClient.getFormDataFromJSON(payload, null, namespace);
    return this.request(url, {
      method: requestMethod, body: formData, unsetContentType: true,
    });
  }

  /**
   * Send HTTP Multipart POST request
   * @param {String} url API endpoint
   * @param {Object} payload
   * @param {string} namespace
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static postWithFormData(url, payload, namespace = null) {
    return RestClient.formDataRequest('POST', url, payload, namespace);
  }

  /**
   * Send HTTP Multipart PUT request
   * @param {String} url API endpoint
   * @param {Object} payload
   * @param {string} namespace
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static patchWithFormData(url, payload, namespace = null) {
    return RestClient.formDataRequest('PATCH', url, payload, namespace);
  }

  /**
   * Creates form data object for attaching files in the request.
   * @param {Object} obj
   * @param {FormData} formData
   * @param {string} namespace
  */
  static getFormDataFromJSON(obj, formData, namespace) {
    const fd = formData || new FormData();
    let formKey;

    for (const property in obj) {
      formKey = namespace ? namespace + '[' + property + ']' : property;

      if (Array.isArray(obj[property])) {
        obj[property].forEach((item) => {
          if (item instanceof File) {
            fd.append(`${formKey}`, item);
          } else {
            RestClient.getFormDataFromJSON(item, fd, `${formKey}[]`);
          }
        });
      } else if (typeof obj[property] === 'object') {
        RestClient.getFormDataFromJSON(obj[property], fd, property);
      } else {
        fd.append(formKey, obj[property]);
      }
    }

    return fd;
  }

}

export default RestClient;
export { extractCsrfToken };
